<template>
<div id="admin-form">
    <NavBar />
    <div class="admin-login" id="admin-login">
        <div class="container flex center">
            <form class="flex login-form" @submit.prevent="login" id="login-form"  autocomplete="off">

                <h2 class="sub-headings centered">
                    Admin <span>Login</span>
                </h2>

                <div class="form-element">
                    <input
                        type="text" data-name="username" data-len="6" v-model="lUsername"
                        id="l-username" class="form-input" placeholder="Username"
                        @input="handleInput" @blur="validateFields">
                </div>

                <div class="form-element">
                    <input
                        type="password" data-name="password" data-len="6" v-model="lPassword"
                        id="l-password" class="form-input" placeholder="Password"
                        @input="handleInput" @blur="validateFields">
                    <div class="eye-sprite open"  @click="showPassword"></div>
                </div>

                <button type="submit" class="btn login-btn">
                    LOGIN
                </button>

            </form>
        </div>
    </div>
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import { Admin } from '@/services/Service.js'
import $ from 'jquery';

export default {
    data() {
        return {
            lUsername: '',
            lPassword: ''
        }
    },
    components: {
        NavBar
    },
    methods: {
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} digits.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        showPassword(e) {
            const type = $(e.target).hasClass('open') ? 'text' : 'password';
            $( e.target ).toggleClass('open');
            $( e.target ).toggleClass('close');
            $('#l-password').attr('type', type );
        },
        async login()
        {
            if( this.lUsername === '' )
                this.addErrorMessage('l-username');
            else if( this.lUsername.length < 6 )
                this.addErrorMessage('l-username', 'username must have atleast 6 chars.' );

            if( this.lPassword === '' )
                this.addErrorMessage('l-password');
            else if( this.lPassword.length < 6 )
                this.addErrorMessage('l-password','password must have atleast 6 chars.');

            if( $('#login-form .error-msg').length ) return;
            $('.login-btn').blur();
            $('.login-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Admin.login({
                    username: this.lUsername,
                    password: this.lPassword
                });

                if( response.status === 200 )
                {
                    $('.login-btn').html(`Login`);
                    const notification = {
                        type: 'success',
                        message: 'Login successful.'
                    };

                    const authToken = response.headers['auth-token'];
                    this.$store.dispatch('loginUser', {
                        username: this.lUsername,
                        authToken
                    });
                    this.$store.dispatch('addNotification', notification);
                    this.$router.push({name: 'orders'});
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message }
                $('.login-btn').html(`login`);

                if( message === 'User not found.') this.lUsername = '';
                else if( message === 'Wrong password.') this.lPassword = '';
                this.$store.dispatch('addNotification', notification);
            }
        }
    }
}
</script>

<style scoped>

.admin-login
{
    height: calc(100vh - 100px);
    width: 100vw;
    min-height: 650px;
    background-image: url('../../assets/Background Images/repair_background.jpg');
    background-size: cover;
    position: relative;
    z-index: 1;
    color: #fff;
}

.admin-login::before
{
    content: '';
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    background-color: rgb(0, 0, 0,0.65);
    z-index: -1;
}

.container
{
    padding: 100px 15px;
    height: 100%;
}

form { flex-direction: column; }

form>* { margin-bottom: 24px; }

form>*:last-child { margin-bottom: 0; }

.login-form
{
    width: 450px;
    align-items: center;
    background-color: rgb(0, 0, 0,0.5);
    padding: 50px;
    margin: 0 auto;
    box-shadow: 0px 5px 10px rgb(255, 255, 255,0.05);
}

h2 { margin-bottom: 50px; }

.form-element { position: relative; }

.form-input
{
    height: 50px; width: 350px;
    border: 2px solid #E20613;
    background-color: rgb(0, 0, 0,0.7);
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.form-input::placeholder { color: #f7f6e7; }

.form-input:focus { border: 2px solid white; }

.eye-sprite
{
    background-image: url('../../assets/eye_sprite.png');
    position: absolute;
    right: 15px; top: 11px;
    cursor: pointer;
}

.eye-sprite.open
{
    background-size: 63.2px 31.6px;
    width: 30px; height: 30px;
    background-position: -0.8px -0.8px;
}

.eye-sprite.close
{
    background-size: 63.2px 31.6px;
    width: 30px; height: 30px;
    background-position: -32.3px -0.8px;
}

.btn
{
    height: 50px; width: 350px;
    border-radius: 3px;
    box-shadow: 0px 0px 6px rgb(255, 255, 255,0.2);
    transition: all 0.1s ease;
    border: 2px solid transparent;
    background-color: #E20613;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
}

.login-btn:hover
{
    background-color: rgb(0, 0, 0,0.25);
    border: 2px solid #E20613;
}

.no-hover { pointer-events: none; background-color: #00AF91 !important; }

@media (max-width: 480px)
{
    .login-form
    {
        width: 100%;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    form, .form-input, .admin-signup .form-row, .sp-row,
    .login-btn, .form-element { width: 100%; }

    .signup-btn, .reset-btn, .otp-box, .verify-btn
    { width: calc(50% - 10px) !important; }
}

</style>